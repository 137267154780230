.service-container {
  background-image: url("../../assets/service/service-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 50px;
  margin-bottom: 100px;
  border-radius: 20px;
}
.our-service-conyainer {
  padding: 0px 10px;
}
.contents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.service-head {
  font-family: patua One;
  font-weight: 400;
  color: #292c3d;
  font-size: 32px;
  margin: 40px 0px;
  position: relative;
}
.service-head::after {
  position: absolute;
  content: url(../../assets/service/new-cap.svg);
  right: 0px;
  top: 0px;
  display: none;
}
.service-content {
  background-color: #ffff;
  border-radius: 20px;
  padding: 30px 20px 20px 20px;
  text-align: center;
}
.service-sub-head {
  font-family: poppins;
  font-weight: 600;
  color: #292c3d;
  font-size: 18px;
  margin-bottom: 30px;
}
.service-sub-cont {
  font-family: poppins;
  font-weight: 400;
  color: #292c3d;
  font-size: 16px;
  line-height: 2;
}
.course-main-container {
  margin-top: 20px;
}
.course-container {
  background-color: #6860b6;
  border-radius: 20px;
  color: #ffff;
  padding: 20px 10px;
  margin-bottom: 20px;
}
.course-head {
  font-family: poppins;
  font-weight: 400;
  font-size: 20px;
  margin: 20px 10px;
}
.course-desc {
  font-family: poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 2;
}
@media (min-width: 662px) {
  .service-head {
    font-size: 46px;
    margin: 100px 0px;
  }
  .service-head::after {
    position: absolute;
    content: url(../../assets/service/new-cap.svg);
    right: -210px;
    top: 100px;
    display: block;
  }
  .service-content {
    border-radius: 20px;
    padding: 60px 40px 40px 40px;
  }
  .service-sub-head {
    font-size: 18px;
    margin-bottom: 50px;
  }
  .service-sub-cont {
    font-size: 18px;
    line-height: 1.9;
  }
  .our-service-conyainer {
    padding: 0px 20px;
  }
  .service-sub-cont {
    font-family: poppins;
    font-weight: 400;
    color: #292c3d;
    font-size: 18px;
    line-height: 1.6;
  }
  .course-main-container {
    margin-top: 20px;
  }
  .course-container {
    background-color: #6860b6;
    border-radius: 20px;
    color: #ffff;
    padding: 40px 30px 90px 30px;
    margin-bottom: 0px;
    margin-bottom: 20px;
  }
  .course-head {
    font-family: poppins;
    font-weight: 400;
    font-size: 29px;
    margin: 50px 30px;
  }
  .course-desc {
    font-family: poppins;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.9;
    letter-spacing: 1px;
  }
}
@media (min-width: 992px) {
  .service-head {
    font-size: 46px;
    margin: 100px 0px;
  }
  .service-head::after {
    position: absolute;
    content: url(../../assets/service/new-cap.svg);
    right: -50px;
    top: 80px;
    display: block;
  }
  .service-content {
    border-radius: 20px;
    padding: 60px 40px 40px 40px;
  }
  .service-sub-head {
    font-size: 18px;
    margin-bottom: 50px;
  }
  .service-sub-cont {
    font-size: 18px;
    line-height: 1.9;
  }
  .our-service-conyainer {
    padding: 0px 30px;
  }
  .service-sub-cont {
    font-family: poppins;
    font-weight: 400;
    color: #292c3d;
    font-size: 18px;
    line-height: 1.6;
  }
  .course-main-container {
    margin-top: 20px;
  }
  .course-container {
    background-color: #6860b6;
    border-radius: 20px;
    color: #ffff;
    padding: 40px 30px 90px 30px;
    /* margin-bottom: 20px; */
  }
  .course-head {
    font-family: poppins;
    font-weight: 400;
    font-size: 29px;
    margin: 50px 30px;
  }
  .course-desc {
    font-family: poppins;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.9;
    letter-spacing: 1px;
  }
}
@media (min-width: 1024px) {
  .service-head {
    font-size: 46px;
    margin: 100px 0px;
  }
  .service-head::after {
    position: absolute;
    content: url(../../assets/service/new-cap.svg);
    right: -250px;
    top: 90px;
    display: block;
  }
  .service-content {
    border-radius: 20px;
    padding: 60px 40px 40px 40px;
    margin: 0px 150px;
  }
  .service-sub-head {
    font-size: 18px;
    margin-bottom: 50px;
  }
  .service-sub-cont {
    font-size: 18px;
    line-height: 1.9;
  }
  .our-service-conyainer {
    padding: 0px 40px;
  }
  .service-sub-cont {
    font-family: poppins;
    font-weight: 400;
    color: #292c3d;
    font-size: 18px;
    line-height: 1.6;
  }
  .course-main-container {
    margin-top: 20px;
  }
  .course-container {
    background-color: #6860b6;
    border-radius: 20px;
    color: #ffff;
    padding: 20px;
    height: 800px;
    /* margin-bottom: 20px; */
  }
  .course-head {
    font-family: poppins;
    font-weight: 400;
    font-size: 29px;
    margin: 30px 20px;
  }
  .course-desc {
    font-family: poppins;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.7;
    letter-spacing: 1px;
  }
}
@media (min-width: 1300px) {
  .service-container {
  }
  .service-head {
    font-size: 46px;
    margin: 100px 0px;
  }
  .service-head::after {
    position: absolute;
    content: url(../../assets/service/new-cap.svg);
    right: -300px;
    top: 80px;
    display: block;
  }
  .service-content {
    text-align: center;
    border-radius: 20px;
    padding: 60px 40px 40px 40px;
    margin: 0px 230px;
  }
  .service-sub-head {
    font-size: 18px;
    margin-bottom: 50px;
  }

  .our-service-conyainer {
    padding: 0px 50px 50px 50px;
  }
  .service-sub-cont {
    font-family: poppins;
    font-weight: 400;
    color: #292c3d;
    font-size: 17px;
    line-height: 2;
  }
  .course-main-container {
    margin-top: 100px;
  }
  .course-container {
    background-color: #6860b6;
    border-radius: 20px;
    color: #ffff;
    padding: 40px 30px 90px 30px;
    height: 800px;
    margin-bottom: 0px;
  }
  .course-head {
    font-family: poppins;
    font-weight: 400;
    font-size: 29px;
    margin: 50px 30px;
  }
  .course-desc {
    font-family: poppins;
    font-weight: 400;
    font-size: 17px;
    line-height: 2;
    letter-spacing: 1px;
  }
}

/* #service {
  background-color: #eae9f6;
}
.service-container {
  padding-bottom: 67px;
}
.service-content {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 45px;
}
.service-lists {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.our-services {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;
}
.heading-para {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.our-service-p {
  font-family: poppins;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 10px;
}
.our-service-head {
  font-family: patua One;
  font-weight: 500;
  font-size: 32px;
  color: #292c3d;
}
.our-service-para {
  font-family: poppins;
  font-weight: 400;
  font-size: 16px;
  color: #292c3d;
  line-height: 2;
}
.first-service-list,
.second-service-list {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 45px 0px 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.first-service-list h5::after {
  content: url(../../assets//service//cap-bg.svg);
  position: absolute;
  left: -30px;
  top: -140px;
}
.second-service-list h5::after {
  content: url(../../assets/service/cap-bg.svg);
  position: absolute;
  left: -30px;
  top: -140px;
}
.first-service-list h5,
.second-service-list h5 {
  font-family: patua One;
  font-weight: 500;
  font-size: 18px;
  color: #292c3d;
  padding: 10px;
  position: relative;
}
.cap-img {
  width: 129px;
  height: 129px;
}
.heading-service {
  font-family: patua One;
  font-weight: 500;
  font-size: 28px;
  color: #8b80f8;
  margin-bottom: 11px;
}
.heading-service2 {
  font-family: patua One;
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  margin-bottom: 11px;
}
@media (min-width: 662px) {
    .first-service-list h5::after {
      left: -100px;
    }
    .second-service-list h5::after {
      left: -100px;
    }
    .our-service-para {
      font-size: 17px;
    }
}
@media (min-width: 992px) {
  .service-container {
    padding-bottom: 67px;
  }
  .service-content {
    gap: 25px;
    margin-top: 45px;
  }

  .service-lists {
    display: flex;
    flex-direction: row;
    gap: 25px;
  }
  .our-services {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 40px;
  }
  .heading-para {
    font-size: 42px;
  }
  .our-service-head {
    font-size: 45px;
  }
  .first-service-list,
  .second-service-list {
    padding: 25px 65px 0px 65px;
    
  }
  .first-service-list h5::after {
    left: -50px;
    top: -240px;
  }
  .second-service-list h5::after {
    left: -50px;
    top: -240px;
  }
  .first-service-list h5,
  .second-service-list h5 {
    font-size: 20px;
  }
  .cap-img {
    width: 139px;
    height: 139px;
  }
}
@media (min-width: 1024px) {
  .our-services {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
}
.first-service-list, .second-service-list {
  padding: 35px 35px 0px 35px;
 
}
.first-service-list h5::after {
  left: -45px;
  top: -150px;
}
.second-service-list h5::after {
  left: -45px;
  top: -150px;
}
}
@media (min-width: 1300px) {
  .service-container {
    padding-bottom: 67px;
  }
  .service-content {
    gap: 35px;
    margin-top: 45px;
  }

  .service-lists {
    display: flex;
    flex-direction: row;
    gap: 35px;
  }
  .our-services {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 65px 40px;
  }
  .heading-para {
    font-size: 42px;
  }
  .our-service-head {
    font-size: 52px;
  }
  .first-service-list,
  .second-service-list {
    padding: 25px 65px 0px 65px;
  }
  .first-service-list h5::after {
    left: -50px;
    top: -150px;
  }
  .second-service-list h5::after {
    left: -50px;
    top: -150px;
  }
  .first-service-list h5,
  .second-service-list h5 {
    font-size: 20px;
  }
  .cap-img {
    width: 139px;
    height: 139px;
  }
}
 */
